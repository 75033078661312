import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import Wysiwyg from '@components/Layout/Wysiwyg'

import { MakeInfoProps } from '@contracts/page'

import * as styles from './MakeInfo.module.scss'

const MakeInfo = (props: MakeInfoProps) => {
	const { make } = props
	const { image, content } = props.carFinance

	return (
		<div className={styles.info}>
			<div className={styles.left}>
				{image.localFile && (
					<GatsbyImage
						image={image.localFile.childImageSharp.gatsbyImageData}
						alt={make}
						className={styles.image}
					/>
				)}
			</div>
			<div className={styles.right}>
				<h2 className={styles.title}>{make} car finance</h2>
				<Wysiwyg className={styles.wysiwyg} content={content}></Wysiwyg>
			</div>
		</div>
	)
}

export default MakeInfo
