import React from 'react'

import Section from '@components/Content/Section/Section'

import { MakeInfoProps } from '@contracts/page'

import MakeInfo from './MakeInfo'
import * as styles from './MakeInfoSection.module.scss'

const MakeInfoSection = (props: MakeInfoProps) => {
	return (
		<Section className={styles.section}>
			<MakeInfo make={props.make} carFinance={props.carFinance} />
		</Section>
	)
}

export default MakeInfoSection
