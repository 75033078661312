import React from 'react'

import Section from '../Section/Section'
import LendersTrustedGrid from './TrustedGrid'
import * as styles from './TrustedSection.module.scss'

type Props = {
	trustedLenders: any
}

const LendersTrustedSection = (props: Props) => {
	return (
		<Section className={styles.section}>
			<h2 className={styles.title}>{props.trustedLenders.title}</h2>
			<LendersTrustedGrid lenders={props.trustedLenders.lenders} />
		</Section>
	)
}

export default LendersTrustedSection
