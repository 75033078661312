import React from 'react'

import { IGatsbyImageData } from 'gatsby-plugin-image'

import WordpressImage from '@components/Wordpress/Image/WordpressImage'

import * as styles from './TrustedGrid.module.scss'

type Props = {
	lenders: TrustedLender[]
}

interface TrustedLender {
	lender: {
		id: string
		title: string
		featuredImage: {
			node: {
				altText: string
				mimeType: string
				sourceUrl: string
				localFile?: {
					childImageSharp: {
						gatsbyImageData: IGatsbyImageData
					}
				}
			}
		}
	}
}

const LendersTrustedGrid = (props: Props) => {
	return (
		<ul className={styles.grid}>
			{props.lenders.map(({ lender }) => {
				const featuredImageNode = lender.featuredImage.node

				return (
					<li key={lender.id} className={styles.item}>
						<WordpressImage
							localFile={featuredImageNode.localFile}
							sourceUrl={featuredImageNode.sourceUrl}
							mimeType={featuredImageNode.mimeType}
							altText={lender.title}
						/>
					</li>
				)
			})}
		</ul>
	)
}

export default LendersTrustedGrid
