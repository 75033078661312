import React from 'react'

import { Script } from 'gatsby'

import Section from '../Section/Section'
import * as styles from './CalculatorSection.module.scss'

const CalculatorSection = () => {
	// const [activeCalculator, setActiveCalculator] = React.useState('hp')

	// const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
	// 	e.preventDefault()

	// 	setActiveCalculator(e.currentTarget.getAttribute('data-toggle')!)
	// }

	// const buttons = () => {
	// 	return (
	// 		<ul className={styles.tabs}>
	// 			<li>
	// 				<button className={styles.button} data-toggle="hp" onClick={(e) => handleTabClick(e)}>
	// 					HP Finance
	// 				</button>
	// 			</li>
	// 			<li>
	// 				<button className={styles.button} data-toggle="pcp" onClick={(e) => handleTabClick(e)}>
	// 					PCP Finance
	// 				</button>
	// 			</li>
	// 		</ul>
	// 	)
	// }

	// const iframes = () => {
	// 	return (
	// 		<div className={styles.iframes}>
	// 			<div id="calculator-hp" hidden={activeCalculator === 'hp' ? false : true}>
	// 				<iframe
	// 					src="https://iframe.app.autoconvert.co.uk/iframe-calculator-carmoney-noapr/"
	// 					frameBorder="0"
	// 					style={{ minHeight: 406 }}
	// 					className={styles.iframe}
	// 				/>
	// 			</div>
	// 			<div id="calculator-pcp" hidden={activeCalculator === 'pcp' ? false : true}>
	// 				<iframe
	// 					src="https://iframe.app.autoconvert.co.uk/iframe-calculator-carmoney-pcpnoapr/"
	// 					frameBorder="0"
	// 					style={{ minHeight: 541 }}
	// 					className={styles.iframe}
	// 				/>
	// 			</div>
	// 		</div>
	// 	)
	// }

	// return (
	// 	<Section noPaddingBottom>
	// 		{buttons()}
	// 		{iframes()}
	// 	</Section>
	// )

	// ABOVE - Old code for iframe toggling

	React.useEffect(() => {
		const jQueryScript = document.createElement('script')
		const iframeScript = document.createElement('script')

		jQueryScript.src = 'https://code.jquery.com/jquery-3.6.1.min.js'
		iframeScript.text = `var AcTarget = "financefinity"; var AcInjectParentScript = document.createElement('script');
		document.body.appendChild(AcInjectParentScript); var acRng = Math.floor(Math.random() * 10000000) + 1;
		AcInjectParentScript.onload = function () {}; AcInjectParentScript.src =
		"https://iframe.app.autoconvert.co.uk/js/iframe/"+AcTarget+"/parent-comms.js?ver=" + acRng;`

		if (document) {
			document.body.appendChild(jQueryScript)
			document.body.appendChild(iframeScript)
		}
	})

	return (
		<>
			<div id="iframe-container"></div>
		</>
	)
}

export default CalculatorSection
