import React, { useEffect } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'
import Container from '@components/Layout/Container'
import Wysiwyg from '@components/Layout/Wysiwyg'

import * as styles from './Hero.module.scss'

import astro from '@images/astro/hurray.png'
import scrollArrowDown from '@images/scroll-arrow-down.svg'

const Hero = () => {
	const [heroHeight, setHeroHeight] = React.useState(0)
	const heroRef = React.useRef<HTMLDivElement>(null)

	useEffect(() => {
		setHeroHeight(heroRef.current?.clientHeight || 0)
	}, [])

	const data = useStaticQuery(graphql`
		query LendersHeroQuery {
			wp {
				lendersSettings {
					lendersOptions {
						singleLenderHeroContent {
							title
							content
							buttonText
						}
					}
				}
			}
		}
	`)

	const heroContent = data.wp.lendersSettings.lendersOptions.singleLenderHeroContent

	return (
		<div className={styles.hero} ref={heroRef}>
			<Container>
				<div className={styles.content}>
					<h1 className={styles.title}>{heroContent.title}</h1>

					<Wysiwyg className={styles.wysiwyg} content={heroContent.content} />

					<Button label={heroContent.buttonText} uri="/apply" large />

					<img
						src={scrollArrowDown}
						alt=""
						className={styles.arrowDown}
						onClick={() => {
							if (window) {
								window.scrollTo({
									top: heroHeight,
									behavior: 'smooth',
								})
							}
						}}
					/>
				</div>

				<img src={astro} alt="" className={styles.astro} />
			</Container>

			<div className={styles.background}>
				<StaticImage
					src="../../../images/car-finance-background.jpg"
					alt=""
					className={styles.image}
					loading="eager"
				/>
			</div>
		</div>
	)
}

export default Hero
