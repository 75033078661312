import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import CalculatorSection from '@components/Content/Calculator/CalculatorSection'
import CountersSection from '@components/Content/Counters/CountersSection'
import Feature from '@components/Content/Feature/Feature'
import LendersTrustedSection from '@components/Content/Lenders/TrustedSection'
import PageLinks from '@components/Content/PageLinks/PageLinks'
import QuoteCTA from '@components/Content/QuoteCTA/QuoteCTA'
import Section from '@components/Content/Section/Section'
import USPGrid from '@components/Content/USP/USPGrid'
import DealsSection from '@components/Deals/DealsSection'
import FAQSection from '@components/FAQ/FAQSection'
import GatsbyHead from '@components/Layout/GatsbyHead'
import OverTheMoon from '@components/OverTheMoon/OverTheMoon'
import Hero from '@components/Pages/CarFinanceSingle/Hero'
import MakeInfoSection from '@components/Pages/CarFinanceSingle/MakeInfoSection'
import Parallax from '@components/Parallax/Parallax'
import TestimonialsSection from '@components/Testimonials/TestimonialsSection'

import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/SEO'
import { wpCarFinanceProps } from '../contracts/page'

const CarFinanceSingle = (props: wpCarFinanceProps) => {
	const { data } = props.pageContext
	const { trustedLenders } = props.pageContext
	const { pageLinks, counters, feature, parallaxImage, overTheMoon } = props.pageContext.data.carFinance

	const featuredImageBkp = feature && feature.image && feature.image.mediaItemUrl ? feature.image.mediaItemUrl : ''
	const featuredImage =
		feature &&
		feature.image &&
		feature.image.localFile &&
		feature.image.localFile.childImageSharp &&
		feature.image.localFile.childImageSharp.gatsbyImageData
			? feature.image.localFile.childImageSharp.gatsbyImageData
			: null

	const parallaxImageJSX = <GatsbyImage image={parallaxImage.localFile?.childImageSharp.gatsbyImageData} alt="" />

	return (
		<Layout template="carmake">
			<Parallax background={<Hero />} curve={false} stickyImage={parallaxImageJSX}>
				<CountersSection counters={counters.counters} />

				<CalculatorSection />

				<LendersTrustedSection trustedLenders={trustedLenders} />

				<TestimonialsSection title="What our customers say" />

				<MakeInfoSection make={data.title} carFinance={data.carFinance} />

				<Section noPaddingBottom>
					<Feature image={featuredImage} imageBkp={featuredImageBkp} title={feature.title} content={feature.content} />
				</Section>

				<Section noPaddingBottom>
					<OverTheMoon data={overTheMoon} />
				</Section>

				<Section noPaddingBottom>
					<USPGrid />
				</Section>

				<Section>
					<PageLinks links={pageLinks.links} />
				</Section>

				<DealsSection />

				<FAQSection />

				<QuoteCTA />
			</Parallax>
		</Layout>
	)
}

export function Head(props: wpCarFinanceProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default CarFinanceSingle
